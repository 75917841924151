import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect, useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import useTheme from 'selectors/use-theme';
import { classNamesHelper } from 'utils';

import Button from 'components/Button';

import { setFullScreen } from 'actions/app';

import styles from './Modal.css';

export default function Modal({
  children,
  onClose,
  onPrev,
  onNext,
  disablePrev,
  disableNext,
}) {
  useStyles(styles);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isFullScreen } = useSelector(state => state.app);
  const { currentProgression } = useSelector(state => state.progression);
  const portalRoot = useRef(null);

  /* eslint no-unused-vars: off */
  const onFullScreen = () => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      dispatch(setFullScreen(false));
      return;
    }
    const element = portalRoot.current;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
    dispatch(setFullScreen(true));
  };
  const checkKey = e => {
    // TODO not working from keys
    // if (e.keyCode == '37') {
    //   onPrev();
    //   return;
    // }
    //
    // if (e.keyCode == '39') {
    //   onNext();
    //   return;
    // }
    if (e.keyCode === '27') {
      onClose();
    }
  };

  const fullScreenExitHandler = () => {
    if (
      !document.isFullScreen &&
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      dispatch(setFullScreen(false));
    }
  };

  useEffect(() => {
    portalRoot.current = document.getElementById('composer-modal-root');
    document.addEventListener('keydown', checkKey);
    document.addEventListener('fullscreenchange', fullScreenExitHandler);
    document.addEventListener('webkitfullscreenchange', fullScreenExitHandler);
    document.addEventListener('mozfullscreenchange', fullScreenExitHandler);
    return () => {
      document.removeEventListener('keydown', checkKey);
      document.addEventListener('fullscreenchange', fullScreenExitHandler);
      document.addEventListener(
        'webkitfullscreenchange',
        fullScreenExitHandler,
      );
      document.addEventListener('mozfullscreenchange', fullScreenExitHandler);
    };
  }, []);

  /* eslint css-modules/no-unused-class: off */
  return (
    <section
      className={classNamesHelper([
        styles['css-modal'],
        styles[theme],
        isFullScreen && styles['full-screen'],
      ])}
      id="modal-progression-view"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modal-progression-view-header"
      aria-hidden="true"
    >
      <div className={styles['css-modal-container']}>
        <div className={classNamesHelper([styles['nav-modal']])}>
          <div
            className={classNamesHelper([
              styles['btn-nav'],
              styles['btn-prev'],
            ])}
          >
            <Button
              disabled={disablePrev}
              type="button"
              buttonType="arrow"
              onClick={onPrev}
              title="Previous Progression"
            >
              &lt;
            </Button>
          </div>
          <div
            className={classNamesHelper([
              styles['btn-nav'],
              styles['btn-next'],
            ])}
          >
            <Button
              disabled={disableNext}
              type="button"
              buttonType="arrow"
              onClick={onNext}
              title="Next Progression"
            >
              &gt;
            </Button>
          </div>
        </div>
        <div className={styles['css-modal_inner']}>
          <div className={styles['css-modal_header']}>
            {/* <Button buttonType="arrow" onClick={onClose}> */}
            {/*  &times; */}
            {/* </Button> */}
            {/* <Button
                buttonType="arrow"
                onClick={onFullScreen}
              >
                Full
              </Button> */}
          </div>
          <div className={styles['css-modal_content']}>{children}</div>
          <footer className={styles['css-modal_footer']} />
        </div>
      </div>

      <button
        type="button"
        onClick={onClose}
        className={styles['css-modal_close']}
        title="Close this modal"
        data-close="Close"
        data-dismiss="modal"
      >
        &times;
      </button>
    </section>
  );
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  disablePrev: PropTypes.bool,
  onNext: PropTypes.func.isRequired,
  disableNext: PropTypes.bool,
};

Modal.defaultProps = {
  disablePrev: PropTypes.bool,
  disableNext: PropTypes.bool,
};
