import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import loadable from '@loadable/component';

import ProgressionListItem from 'components/Progression/ProgressionListItem';
import Button from 'components/Button';
import ModalPlayer from 'components/ModalPlayer';
import { BlockSpace } from 'components/Common/Spacing';
import UserProfileHead from 'components/User/UserProfile';
import LoadingView from 'components/LoadingView';

import { searchUserProgression } from 'actions/progression';

import styles from './Profile.css';

const LIMIT = 10;

const loadingOtions = {
  fallback: <LoadingView />,
  ssr: true,
};

const NotFoundContainerLoadable = loadable(
  () =>
    import(/* webpackChunkName: 'containers-NotFound' */ 'containers/NotFound'),
  loadingOtions,
);

export default function Profile({ params }) {
  useStyles(styles);
  const dispatch = useDispatch();
  const {
    progressionSlug,
    userProgressions: progressions,
    userProgressionPagination: pagination,
  } = useSelector(state => state.progression);

  const { entityProfile, fetchEntityError, responseStatus } = useSelector(
    state => state.user,
  );

  useEffect(() => {
    if (entityProfile && entityProfile._id) {
      dispatch(searchUserProgression({ query: { user: entityProfile._id } }));
    }
  }, [entityProfile]);

  const loadMoreProgressions = () => {
    if (!pagination) return;
    dispatch(
      searchUserProgression({
        query: {
          page: pagination.currentPage + 1,
          limit: LIMIT,
          user: entityProfile._id,
        },
        append: true,
      }),
    );
  };

  if (fetchEntityError && responseStatus === 404) {
    return <NotFoundContainerLoadable />;
  }

  return (
    <div>
      <UserProfileHead userSlug={params.slug} />
      <BlockSpace size={50} />
      {progressionSlug && <ModalPlayer backgroundPage="user" />}
      {progressions &&
        progressions.map(progression => {
          return (
            <ProgressionListItem
              basePath="user"
              key={progression._id}
              progression={progression}
            />
          );
        })}
      <Button
        disabled={pagination?.currentPage >= pagination?.totalPages}
        onClick={loadMoreProgressions}
        buttonType="grey"
      >
        Show More Progressions
      </Button>
    </div>
  );
}

Profile.propTypes = {
  params: PropTypes.object.isRequired,
};
