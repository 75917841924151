// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3JPa1{display:inline-block;padding:3px 8px;background-color:rgba(0,0,0,0);color:var(--tertiary-shade-darker-20);text-decoration:none;-webkit-appearance:none;-moz-appearance:none;appearance:none;border:none}._3JPa1:hover{cursor:pointer}._3Yek5{color:var(--tertiary-shade-lighter-30)}", ""]);
// Exports
exports.locals = {
	"logout-button": "_3JPa1",
	"dark-mode": "_3Yek5"
};
module.exports = exports;
