import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import Portal from 'components/Portal';
import Modal from 'components/Modal';
import PlayerContainer from 'containers/Player';

import { setProgressionSlug } from 'actions/progression';

import useModalRoute from 'hooks/use-modal-route';
import usePlayer from 'hooks/use-player';

import history from 'utils/history';

export default function ModalPlayer({ backgroundPage }) {
  useModalRoute();
  const dispatch = useDispatch();
  const { stop: stopPlayer } = usePlayer();
  const { progressionSlug, prev, next } = useSelector(
    state => state.progression,
  );

  const handleModalClose = () => {
    dispatch(setProgressionSlug(''));
    stopPlayer();
    if (history.location.state?.background.pathname) {
      history.push({
        pathname: history.location.state.background.pathname,
      });
      return;
    }
    history.goBack();
  };

  // navigation are exact opposite.
  // for next show older items i.e. prev progression
  // for prev show newer items i.e. next progression.
  const onPrev = () => {
    if (!next) return;
    stopPlayer();
    history.push({
      pathname: `/p/${next.slug}`,
      state: history.location.state,
    });
  };

  const onNext = () => {
    if (!prev) return;
    stopPlayer();
    history.push({
      pathname: `/p/${prev.slug}`,
      state: history.location.state,
    });
  };

  return (
    <Portal portalType="modal">
      <Modal
        onClose={handleModalClose}
        onPrev={onPrev}
        disablePrev={!next}
        onNext={onNext}
        disableNext={!prev}
      >
        <PlayerContainer
          params={{ progressionSlug }}
          backgroundPage={backgroundPage}
          isModalView
        />
      </Modal>
    </Portal>
  );
}

ModalPlayer.propTypes = {
  backgroundPage: PropTypes.string,
};

ModalPlayer.defaultProps = {
  backgroundPage: 'discover',
};
