import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setProgressionSlug } from 'actions/progression';
import history from 'utils/history';

export default function useModalRoute() {
  const dispatch = useDispatch();
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === 'POP') {
        dispatch(setProgressionSlug(''));
      }
    });
    return () => {
      return unlisten();
    };
  }, []);
}
