import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
// import { useDispatch, useSelector } from 'react-redux';
// import Tone from 'tone';
import * as heirarchies from 'design-system/inventory/heirarchy';

import Link from 'components/Link';
import Creator from 'components/User/Creator';
import Date from 'components/Date';
import Separator from 'components/Separator';
import {
  DummyTitle,
  DummyDetail,
  DummySettings,
  DummyUser,
} from 'components/LoadingView';
import { Text, Paragraph, Title } from 'components/Common/Typography';
import { BlockSpace, InlineSpace } from 'components/Common/Spacing';
import { Badge } from 'components/Common/Badge';

import { classNamesHelper, getShortTitle } from 'utils';
import useTheme from 'selectors/use-theme';

import { BASE_ROUTE_PROGRESSION } from 'types/app';

import history from 'utils/history';

import styles from './ProgressionListItem.css';

const getShortProgressionText = progressionText => {
  return (
    progressionText &&
    progressionText
      .substr(0, 100)
      .replace(/\/\//, '')
      .replace(/\n/g, '<br />')
  );
};

const SettingsItem = ({ settings, name }) => {
  if (!settings[name]) return null;
  return <Badge heirarchy={heirarchies.TERTIARY}>{settings[name]}</Badge>;
};

SettingsItem.propTypes = {
  settings: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

const Name = ({ children }) => <Title>{children}</Title>;

Name.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function ProgressionListItem({
  progression,
  showUser,
  basePath,
}) {
  useStyles(styles);
  const theme = useTheme();

  const onClickHandler = useCallback(
    progressionSlug => () => {
      history.push({
        pathname: `/p/${progressionSlug}`,
        state: {
          background: history.location,
          basePath,
          progressionModalRoute: true,
        },
      });
    },
    [history],
  );

  /* eslint css-modules/no-unused-class: off */
  /* eslint react/no-danger: off */
  if (progression.loading) {
    return (
      <div
        key={`progression-item-${progression._id}`}
        className={classNamesHelper([
          styles['progression-list-item'],
          styles[theme],
        ])}
      >
        <DummyTitle />
        <DummyDetail />
        <DummySettings />
        <DummySettings />
        <DummySettings />
        <DummySettings />
        <DummyUser />
        <BlockSpace size={200} />
      </div>
    );
  }
  return (
    <div
      key={`progression-item-${progression._id}`}
      className={classNamesHelper([styles['progression-list-item']])}
      onClick={onClickHandler(progression.slug)}
      role="link"
      onKeyDown={onClickHandler(progression.slug)}
      tabIndex="0"
    >
      <Link
        to={`${BASE_ROUTE_PROGRESSION}/${progression.slug}`}
        isListItem
        state={{
          background: history.location,
          basePath,
          progressionModalRoute: true,
        }}
        cancelBubble
      >
        <Name>{getShortTitle(progression.name)}</Name>
      </Link>
      <BlockSpace size={50} />
      <Paragraph heirarchy={heirarchies.SECONDARY}>
        <span
          dangerouslySetInnerHTML={{
            __html: getShortProgressionText(progression.progressionText),
          }}
        />
      </Paragraph>
      <BlockSpace size={50} />
      <div>
        <SettingsItem settings={progression.settings} name="octave" />
        <InlineSpace size={25} />
        <SettingsItem settings={progression.settings} name="bpm" />
        <InlineSpace size={25} />
        <SettingsItem settings={progression.settings} name="instrument" />
        <InlineSpace size={25} />
        <SettingsItem settings={progression.settings} name="arpeggiateStyle" />
      </div>
      <div className={styles.footer}>
        {showUser && progression.createdBy && (
          <Creator user={progression.createdBy} />
        )}
        {showUser ? <Separator /> : null}
        {progression.cloned && (
          <>
            <Text heirarchy={heirarchies.PLACEHOLDER}>Cloned from </Text>
            <Link to={`/p/${progression.cloned.slug}`} cancelBubble>
              <Text heirarchy={heirarchies.TERTIARY}>
                {progression.cloned.slug}
              </Text>
            </Link>
          </>
        )}
        {progression.cloned ? <Separator /> : null}
        {progression && (
          <Text heirarchy={heirarchies.PLACEHOLDER}>
            <Date date={progression.createdAt} />
          </Text>
        )}
        {!progression?.loading && (
          <>
            <Separator />
            <Link to={`/p/${progression.slug}/edit`} cancelBubble>
              <Text heirarchy={heirarchies.TERTIARY}>Editor</Text>
            </Link>
          </>
        )}
      </div>
      <BlockSpace size={200} />
    </div>
  );
}

ProgressionListItem.propTypes = {
  progression: PropTypes.object.isRequired,
  showUser: PropTypes.bool,
  basePath: PropTypes.string,
};

ProgressionListItem.defaultProps = {
  showUser: false,
  basePath: '',
};
