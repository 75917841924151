import React from 'react';
import Layout from 'layouts/Main';
import Profile from 'containers/User/Profile';

async function action(context) {
  return {
    routeName: 'user',
    title: 'Composer Studio',
    chunks: ['user'],
    params: context.params,
    component: (
      <Layout>
        <Profile params={context.params} />
      </Layout>
    ),
  };
}

export default action;
