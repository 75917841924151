// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root{--label-font-size:14px;--test-color:#fff}._16kVI{margin:0 -8px;padding:8px;border-radius:var(--size-static-25);border:1px solid rgba(0,0,0,0)}._16kVI:hover{border:1px solid var(--neutral-shade-lighter-30);cursor:pointer}._2hjnW{margin-bottom:.3em;font-weight:500}._2JG-o{margin:.25em;padding:0}.RTNEi{margin-top:.7em}", ""]);
// Exports
exports.locals = {
	"progression-list-item": "_16kVI",
	"item-name": "_2hjnW",
	"progression-text": "_2JG-o",
	"footer": "RTNEi"
};
module.exports = exports;
