import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import PropTypes from 'prop-types';

import { classNamesHelper } from 'utils';
import { HEIRARCHIES } from 'design-system/inventory/heirarchy';
import useTheme from 'selectors/use-theme';
import styles from './Badge.css';

/* eslint css-modules/no-unused-class: off */
// eslint-disable-next-line import/prefer-default-export
export function Badge({ heirarchy, children }) {
  useStyles(styles);
  const appTheme = useTheme();

  return (
    <span
      className={classNamesHelper([
        styles.badge,
        styles[`badge-${heirarchy}`],
        styles[`${appTheme}-badge-${heirarchy}`],
      ])}
    >
      {children}
    </span>
  );
}

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  heirarchy: PropTypes.oneOf(HEIRARCHIES).isRequired,
};
