import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { H3, TertiaryHierarchyText } from 'components/Common/Typography';
import { BlockSpace } from 'components/Common/Spacing';
import Separator from 'components/Separator';

import useTheme from 'selectors/use-theme';
import { fetchEntityBySlug, logout } from 'actions/user';
import { classNamesHelper } from 'utils';

import { LOGOUT_SUCCESS } from 'types/user';
import { setAccessToken } from 'actions/app';

import { newProgression } from 'actions/progression';
import { clearPlayer } from 'actions/player';

import history from 'utils/history';

import styles from './UserProfile.css';

export default function UserProfile({ userSlug }) {
  useStyles(styles);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { user: authUser } = useSelector(state => state.user);
  const { entityProfile } = useSelector(state => state.user);

  useEffect(() => {
    if (userSlug) {
      dispatch(fetchEntityBySlug(userSlug));
    }
  }, [userSlug]);

  const postLogout = async () => {
    const logOutResponse = await dispatch(logout());
    if (logOutResponse.type === LOGOUT_SUCCESS) {
      dispatch(setAccessToken(''));
      dispatch(newProgression());
      dispatch(clearPlayer());
      history.replace('/');
    }
  };

  /* eslint css-modules/no-unused-class: off */
  const showLogoutButton = () => {
    if (authUser && entityProfile && authUser._id === entityProfile._id) {
      return (
        <>
          <Separator />
          <button
            className={classNamesHelper([
              styles['logout-button'],
              styles[theme],
            ])}
            type="button"
            onClick={postLogout}
          >
            Logout
          </button>
        </>
      );
    }
    return null;
  };

  /* eslint css-modules/no-unused-class: off */
  return (
    <div>
      <H3>{entityProfile && entityProfile.name}</H3>
      <BlockSpace size={50} />
      <div>
        <TertiaryHierarchyText>
          {entityProfile && entityProfile.username}
        </TertiaryHierarchyText>
        {showLogoutButton()}
      </div>
      <BlockSpace size={100} />
      <H3 hierarchy="tertiary">Compositions</H3>
      {/* <div> */}
      {/*  <Button buttonType="tab"> */}
      {/*    <Link to={`/${userSlug}`} isActive>Compositions</Link> */}
      {/*  </Button> */}
      {/*  <InlineSpace size={25} /> */}
      {/*  <Button buttonType="tab"> */}
      {/*    <Link to={`/${userSlug}/midi`} isActive>Midi</Link> */}
      {/*  </Button> */}
      {/* </div> */}
    </div>
  );
}

UserProfile.propTypes = {
  userSlug: PropTypes.string.isRequired,
};
